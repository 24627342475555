import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Head from 'components/head';
import SectionMain from 'components/section-main';
import SectionMainHead from 'components/section-main-head';
import Title from 'components/title';
import CtaButton from 'components/ctabutton';
import ProfileImage from 'components/profile-image';
import List from 'components/list';
import { AsideWithGraph, AsideFull } from 'components/section-main/aside.css';
import PatternSea from 'images/pattern-sea.svg';
import PatternTriangle from 'images/pattern-triangle.svg';
import PatternTripleTrianglePink from 'images/pattern-triple-triangle-pink.svg';
import PatternSquareBlu from 'images/pattern-square-blu.svg';
import PatternTriangle4Inline from 'images/pattern-triangle4-inline.svg';
import COLORS from 'constants/colors';

const Team = ({ data }) => {
  const PAGE = data.teamJson;
  const CLAUDIA = data.teamJson.sections[0];
  const TILIA = data.teamJson.sections[1];
  const DIRETTIVO = data.teamJson.sections[2];

  return (
    <>
      <Head pageTitle={PAGE.title} />

      <SectionMainHead data={PAGE} />

      <SectionMain>
        <ProfileImage color="green">
          <Img fluid={CLAUDIA.aside.imageProfile.childImageSharp.fluid} />
        </ProfileImage>

        <Title as="h2" color="blue">
          {CLAUDIA.title}
        </Title>

        <Title
          as="h3"
          color="pink"
          size="extrasmall"
          className="offsetRight-tablet"
        >
          {CLAUDIA.subTitle}
        </Title>

        <div
          className="offsetRight-tablet"
          dangerouslySetInnerHTML={{
            __html: CLAUDIA.content.childMarkdownRemark.html,
          }}
        />

        <CtaButton
          linkTo="/claudia-bertuccelli"
          fontWeight="bold"
          style="rounded"
        >
          {CLAUDIA.cta}
        </CtaButton>

        <AsideWithGraph theme={{ align: 'right' }}>
          <PatternSea stroke={COLORS.lightBlue} />
          <Img fluid={CLAUDIA.aside.image.childImageSharp.fluid} />
          <PatternTriangle4Inline fill={COLORS.white} />
          <PatternTriangle fill={COLORS.pink} />
        </AsideWithGraph>
      </SectionMain>

      <SectionMain
        theme={{
          asideAlign: 'left',
        }}
      >
        <ProfileImage color="green">
          <Img fluid={TILIA.aside.imageProfile.childImageSharp.fluid} />
        </ProfileImage>

        <Title as="h2" color="blue">
          {TILIA.title}
        </Title>

        <Title
          as="h3"
          color="pink"
          size="extrasmall"
          className="offsetRight-tablet"
        >
          {TILIA.subTitle}
        </Title>

        <div
          className="offsetRight-tablet"
          dangerouslySetInnerHTML={{
            __html: TILIA.content.childMarkdownRemark.html,
          }}
        />

        <CtaButton linkTo="/tilia-ruggeri" fontWeight="bold" style="rounded">
          {TILIA.cta}
        </CtaButton>

        <AsideWithGraph theme={{ align: 'left' }}>
          <PatternSea stroke={COLORS.pink} />
          <Img fluid={TILIA.aside.image.childImageSharp.fluid} />
          <PatternTriangle4Inline fill={COLORS.lightBlue} />
          <PatternTriangle fill={COLORS.darkGreen} />
        </AsideWithGraph>
      </SectionMain>

      <SectionMain bgColor="blue" bgPattern={true}>
        <PatternSquareBlu />
        <Title as="h2" color="pink" bordered={true} size="small">
          {DIRETTIVO.title}
        </Title>

        <AsideFull>
          <List
            className="doubleColumn"
            list={DIRETTIVO.list}
            textColor="white"
            colorUnderline="darkGreen"
          />
        </AsideFull>
        <PatternTripleTrianglePink />
      </SectionMain>
    </>
  );
};

Team.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Team;

export const query = graphql`
  query TeamQuery {
    teamJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      sections {
        title
        subTitle
        cta
        content {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        aside {
          imageProfile {
            childImageSharp {
              fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image {
            childImageSharp {
              fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        list {
          title
          subTitle
        }
      }
    }
  }
`;
